<template>
  <div>
    <router-link to="/calendar/add"><CButton color="success" block>{{$t('Создать календарь')}}</CButton></router-link>
    <br>
    <CRow>
      <CCol sm="12">
        <CDataTable
            :items="calendars"
            :fields="fields"
            :noItemsView="{noItems:$t('Нет календарей')}"
        >
          <template #visitors="{item}">
            <td>
              <router-link class="calendars_stat_link" :to="'/calendar/stat/' + item.id + '/visitors/1'" :title="$t('Перейти в статистику')">{{item.visitors}}</router-link>
            </td>
          </template>
          <template #subscribed="{item}">
            <td>
              <router-link class="calendars_stat_link" :to="'/calendar/stat/' + item.id + '/subscribers/1'" :title="$t('Перейти в статистику')">{{item.subscribed}}</router-link>
            </td>
          </template>
          <template #action="{item}">
            <td>
              <router-link class="calendars_action_link" :to="'/calendar/' + item.id" :title="$t('Перейти в календарь')"><CButton size="sm" color="info">{{$t('Перейти в календарь')}}</CButton></router-link>
              <router-link class="calendars_action_link" :to="'/calendar/edit/' + item.id" :title="$t('Редактировать')"><CButton size="sm" color="success">{{$t('Редактировать')}}</CButton></router-link>
              <a class="calendars_action_link" to="" @click="copy(item.id)" :title="$t('Дублировать')"><CButton size="sm" color="warning">{{$t('Дублировать')}}</CButton></a>
              <a class="calendars_action_link" to="" @click="remove(item.id)" :title="$t('Удалить')"><CButton size="sm" color="danger">{{$t('Удалить')}}</CButton></a>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import axios from '@/modules/axios'

export default {
  name: 'Calendars',
  data () {
    return {
      calendars: [],
      fields: [
        {key: 'id', label: 'Id', sortable: true},
        {key: 'name', label: this.$t('Название'), sortable: true},
        {key: 'conversion', label: this.$t('Конверсия') + ', %', sortable: true},
        {key: 'visitors', label: this.$t('Посетителей'), sortable: true},
        {key: 'subscribed', label: this.$t('Подписчиков'), sortable: true},
        {key: 'unsubscribed', label: this.$t('Отписавшихся'), sortable: true},
        {
          key: 'action',
          label: '',
          _style: { width: '1%' },
          sorter: false,
          filter: false
        },
      ]
    }
  },
  created() {
    this.getCalendars()
  },
  methods: {
    getCalendars () {
      axios.get('/calendar/list', {
        params: {withStat: true}
      }).then((res) => {
        this.calendars = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    remove(id) {
      if(confirm(this.$t('Вы уверены, что хотите удалить календарь ?'))){
        axios.post('/calendar/remove', {
          id: id
        }, {
          headers: {'content-type': 'application/json'}
        }).then(() => {
          this.getCalendars()
        }).catch((error) => {
          this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
        })
      }
    },
    copy(id) {
      axios.post('/calendar/copy', {
        id: id
      }, {
        headers: {'content-type': 'application/json'}
      }).then(() => {
        this.getCalendars()
      }).catch((error) => {
        this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
      })
    }
  }
}
</script>
<style lang="scss">
a:hover {
  text-decoration: none;
}
.calendars_action_link {
  color: black!important;
  cursor: pointer;
  display: block;
  text-decoration: underline !important;
  margin: 5px 0;
}
.calendars_action_link:hover {
  color: gray!important;
}
.calendars_stat_link {
  color: #0d86ff;
  text-decoration: underline !important;
  margin: 5px 0;
}
</style>
